// Generated by Framer (66beb72)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["fnyujuI6G", "fNBnbGKvZ"];

const serializationHash = "framer-i5RKP"

const variantClassNames = {fNBnbGKvZ: "framer-v-1505cqk", fnyujuI6G: "framer-v-b1hylr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.75, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "fnyujuI6G", "Variant 2": "fNBnbGKvZ"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "fnyujuI6G"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "fnyujuI6G", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition {...addPropertyOverrides({fNBnbGKvZ: {value: transition1}}, baseVariant, gestureVariant)}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-b1hylr", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"fnyujuI6G"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{background: "linear-gradient(296deg, rgba(199, 119, 186, 0.62) 10%, rgb(10, 55, 160) 53%, rgb(0, 0, 0) 97.74774774774775%)", ...style}} variants={{fNBnbGKvZ: {background: "linear-gradient(296deg, rgba(199, 119, 186, 0.62) 30.180202174831084%, rgb(10, 55, 160) 78.5856075802365%, rgb(0, 0, 0) 97.74774774774775%)"}}} {...addPropertyOverrides({fNBnbGKvZ: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-i5RKP.framer-9xo9h, .framer-i5RKP .framer-9xo9h { display: block; }", ".framer-i5RKP.framer-b1hylr { height: 1622px; overflow: hidden; position: relative; width: 1366px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1622
 * @framerIntrinsicWidth 1366
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"fNBnbGKvZ":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameriQWgNs2Uh: React.ComponentType<Props> = withCSS(Component, css, "framer-i5RKP") as typeof Component;
export default FrameriQWgNs2Uh;

FrameriQWgNs2Uh.displayName = "backgroundCases";

FrameriQWgNs2Uh.defaultProps = {height: 1622, width: 1366};

addPropertyControls(FrameriQWgNs2Uh, {variant: {options: ["fnyujuI6G", "fNBnbGKvZ"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FrameriQWgNs2Uh, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})